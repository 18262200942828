@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: Arial, Helvetica, sans-serif;
  scrollbar-color: white black;
}

html {
  scrollbar-color: white black;
}
.site {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  color: #435ffb;
  text-transform: uppercase;
}
/*loading  by https://codepen.io/ruben-vardanyan/pen/zYxEgNG */
.loader-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader-inner {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 30px;
}
.st1 {
  position: absolute;
  top: 10%;
  left: 0;
  background: url('https://images.vexels.com/media/users/3/153359/isolated/preview/f253c46ff6fb727415fc70750ac1fb6e-icono-de-trazo-de-color-de-ajustes-del-sistema-by-vexels.png')
    no-repeat center / cover;
  width: 50px;
  height: 50px;

  animation: stid 2.5s linear infinite;
}
.st2 {
  position: absolute;
  top: 20%;
  left: 43%;

  width: 50px;
  height: 50px;
  background: url('https://images.vexels.com/media/users/3/153359/isolated/preview/f253c46ff6fb727415fc70750ac1fb6e-icono-de-trazo-de-color-de-ajustes-del-sistema-by-vexels.png')
    no-repeat center / cover;
  animation: stidd 2.5s linear infinite;
}
.st3 {
  position: absolute;
  top: 60%;
  left: 30%;
  background: url('https://images.vexels.com/media/users/3/153359/isolated/preview/f253c46ff6fb727415fc70750ac1fb6e-icono-de-trazo-de-color-de-ajustes-del-sistema-by-vexels.png')
    no-repeat center / cover;
  width: 30px;
  height: 30px;

  animation: stid 2.5s linear infinite;
}
@keyframes stid {
  to {
    transform: rotate(360deg);
  }
}
@keyframes stidd {
  to {
    transform: rotate(-360deg);
  }
}

.item {
  cursor: pointer;
  position: fixed;
  display: none;
  bottom: 2%;
  right: 4vw;
  transition: all 1s;
  z-index: 1001;
}

.fade-in {
  animation: fadeIn 3s;
  -moz-animation: fadeIn 3s; /* Firefox */
  -webkit-animation: fadeIn 3s; /* Safari and Chrome */
  -o-animation: fadeIn 3s; /* Opera */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#mobile-footer {
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.b-none {
  bottom: none;
}

.b-0 {
  bottom: 0;
}

#mobile-footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;
}
.mobile-link {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.mobile-link a {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}
#mobile-footer-close {
  position: relative;
}

/* .mobile-menu-hide {
  animation: hideFooter 5s forwards;
}
.mobile-menu-show {
  animation: showFooter 0.5s forwards;
} */
@keyframes hideFooter {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes showFooter {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.float {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 14px;
  right: 24px;

  background-color: rgb(0, 0, 0);
  color: #fff;
  border-radius: 50px;
  border: 1px solid white;
  text-align: center;
}

.my-float {
  margin-top: 22px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

.spinner {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rocket {
  transform: rotate(22deg);
}

::-webkit-scrollbar {
  background-color: black;
  width: 10px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background-color: black;
}
::-webkit-scrollbar-track-piece {
  background-color: black;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: white;
  border-radius: 2px;
}
::-webkit-scrollbar-corner {
  background-color: black;
}
::-webkit-resizer {
  background-color: white;
}

@keyframes move {
  50% {
    top: 100vh;
  }
}

.max-w-screen {
  max-width: 100vw;
}

.btn:focus {
    outline: none !important;
    outline-offset: none !important;
}